import './new-program.styles.scss';

const NewProgram = () => {
  return (
    <div className='new-program'>
      <h2 className='h h--xl'>Antrenează-ți Copilul pentru Succes!</h2>

      <h3>📢 Înscrieri Deschise pentru Programul Online  pentru Copii"</h3>
      {/* <h3>🎒 Jurnal De Suflet - De Vorbă cu Mine 🎒 "!</h3> */}
      <p>
        🎉 Dragi părinți, suntem încântați să vă anunțăm lansarea unui program
        online destinat copiilor cu vârste cuprinse între 10 și 18 ani!
      </p>
      <p>
        Utilizând platforma Zoom, oferim sesiuni personalizate și interactive
        care vor ajuta la dezvoltarea personală și socială a copiilor voștri.
      </p>
      <div className='new-program__box'>
        <h3>🌈 Ce oferim?</h3>
        <ul>
          <li>
            Sesiuni relaxante și fără stres, adaptate nevoilor fiecărui copil.
          </li>
          <li>
            Opțiuni de sesiuni individuale (1 la 1) sau în grupe de câte maxim 4
            copii.
          </li>
          <li>
            Teme importante de dezbatere: bullying, stima de sine, influența
            anturajului, importanța eșecului în drumul spre succes.
          </li>
          <li>
            Discuții pe baza unor capitole din cărți recomandate pentru vârsta
            lor.
          </li>
          <li>Vizionarea de filmulețe educative pe diverse teme.</li>
          <li>Fișe de lucru atractive și interactive.</li>
          <li>
            Posibilitatea fiecărui copil de a propune idei și subiecte de
            dezbatere.
          </li>
          <li>Un mediu de susținere și încurajare, fără etichete!</li>
        </ul>
      </div>
      <div className='new-program__box'>
        <h3>💬 De ce să participați?</h3>
        <ul>
          <li>
            Îi ajutăm pe copii să își dezvolte abilitățile de comunicare și
            gândire critică.
          </li>
          <li>
            Creăm un spațiu sigur în care să se simtă înțeleși și apreciați.
          </li>
          <li>
            Îi încurajăm să-și exprime ideile și să colaboreze cu alți copii de
            vârsta lor.
          </li>
        </ul>
      </div>
      <div className='new-program__box'>
        <h3>🎨 Activități diverse și captivante!</h3>
        <ul>
          <li>Ateliere de creație și discuții pe teme de interes.</li>
          <li>Jocuri educative și interactive.</li>
          <li>
            Întâlniri cu invitați speciali care să inspire și să motiveze
            copiii.
          </li>
        </ul>
      </div>

      <div className='new-program__box'>
        <h3>SESIUNILE DE LUCRU SUNT:</h3>
        <p>
          🔑 Stima de sine: Copiii învață să își recunoască valoarea și să aibă
          încredere în propriile abilități, devenind mai siguri pe ei în orice
          situație. - Timp de lucru: 2 ore
        </p>
        <p>
          🎓 Cum să am succes la școală: Îi ghidăm să descopere metode eficiente
          de învățare și organizare, pentru a obține rezultate mai bune și a se
          simți motivați.  - Timp de lucru: 2 ore
        </p>
        <p>
          🗣 Cum să nu îmi fie frică să îmi exprim părerea: Le arătăm cum să își
          comunice ideile fără teamă, dezvoltându-și curajul și abilitățile de
          exprimare. - Timp de lucru: 2 ore
        </p>
        <p>
          🚫 Bullying-ul și cum să îl combat: Copiii învață să recunoască și să
          gestioneze bullying-ul, dezvoltându-și puterea de a-l confrunta și
          preveni. - Timp de lucru: 2 ore
        </p>
        <p>
          👤 Imaginea de sine: Îi ajutăm să își construiască o imagine pozitivă
          despre ei înșiși, să fie împăcați cu propria persoană și să se accepte
          așa cum sunt. - Timp de lucru: 2 ore
        </p>
        <p>
          🌱 Teama de nou: Îi pregătim să facă față schimbărilor și noutăților
          fără anxietate, învățând cum să gestioneze provocările necunoscute.
          Fie că este vorba de mutarea într-o altă localitate sau țară, sau de
          tranzițiile importante de la clasa a 4-a la a 5-a și de la clasa a 8-a
          la a 9-a, îi ajutăm să abordeze aceste schimbări cu
          încredere și curiozitate. - Timp de lucru: 2 ore
        </p>
      </div>

      <h4>
        Nu ratați această oportunitate unică de a oferi copilului vostru o
        experiență educativă și recreativă valoroasă!
      </h4>

      <h3>Cum pot beneficia de program?</h3>
      <p>
        Pentru copii sedinta de 2 ore presupune o donatie de minim 40 lei.
        Donatie se face prin transfer direct in contul asociatiei cu
        specificatia "DONATIE" Dupa efectuarea transferului se trimite pe
        e-mail-ul asociatiei dovada donatiei si datele dvs de contact. In urma
        donatiei veti fi contactati pentru program.
      </p>
    </div>
  );
};

export default NewProgram;
