import './home-page.scss';
import { useState } from 'react';

// Context
import { useNotifications } from '../../context/notifications.context';

// Components
import NavigationBar from '../../components/Navigation/NavigationBar';
import NewsButton from '../../components/news-button/newsButton.component';
import NewsPanel from '../../components/news-panel/newsPanel.component';
import CharityAction from '../../components/charity-action/CharityAction';
import DonationPanel from '../../components/donation-panel/DonationPanel';

// UI elements
import ScopeUI from '../../ui/scope/scope.ui';
import NewProgram from '../../ui/new-program/NewProgram.ui';
import MissionUI from '../../ui/mission-ui/mission.ui';
import VisionUI from '../../ui/vision-ui/vision.ui';
import SuportPanel from '../../ui/suportPanel/suportPanel.ui';
import PartnersUI from '../../ui/partners-ui/partners.ui';
import Reviews from '../../ui/reviews/Reviews';
import StopParenting from '../../ui/stop-parenting/StopParenting';
// import VoluntarsUI from '../../ui/voluntars/voluntars.ui';
// import ProjectsUI from '../../ui/projects-ui/projects.ui';
// import TeamUI from '../../ui/team/team.ui';
import FormularAnpc from '../../ui/formular-anpc/formular-anpc.ui';
import ArticleUI from '../../ui/article/article.ui';
import FooterUI from '../../ui/footer/footer.ui';

const HomePage = () => {
  const [info, setInfo] = useState(false);
  const { activePanel } = useNotifications();

  const handleInfo = () => setInfo(true);
  const closePanel = () => setInfo(false);

  const handlePayment = () => {
    window.open('https://donate.stripe.com/7sI5nAeVc1gs7v24gg', '_blank');
    // setInfo(true);
  };
  return (
    <>
      <NavigationBar />
      <div className='home-page'>
        <NewsButton />
        {activePanel && <NewsPanel />}
        <div className='home-page__welcome'>
          <div className='home-page__welcome--text'>
            <h1 className='h h--xxl'>
              <span>Împreună</span> construim viitorul
            </h1>
            <h3 className='text-handwriting'>
              Ajută-ne să ajutăm. Investește în educație,{' '}
              <span>investește în viitor!</span>
            </h3>
          </div>

          <DonationPanel
            info={info}
            handleInfo={handleInfo}
            handlePayment={handlePayment}
            closePanel={closePanel}
          />
        </div>
        <CharityAction />
        <StopParenting />
        <NewProgram />
        <ScopeUI />
        <MissionUI />
        <VisionUI />
        <SuportPanel />
        <FormularAnpc />
        <PartnersUI />
        {/* <VoluntarsUI /> */}
        {/* <ProjectsUI /> */}
        {/* <TeamUI /> */}

        <ArticleUI />
        <Reviews />
      </div>
      <FooterUI />
    </>
  );
};

export default HomePage;
