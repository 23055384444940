import './stop-parenting.styles.scss';

const StopParenting = () => {
  return (
    <section className='stop-parenting'>
      <div className='stop-parenting__container'>
        <div className='stop-parenting__image'></div>
        <div className='stop-parenting__content'>
          <h2 className='h h--xl'>
            Stop <span>parenting</span>
          </h2>
          <h3>Bun venit în lumea comunicării deschise</h3>
          <p>
            Cum să recâștigi încrederea copilului tău – Metode prin care să
            încurajezi copilul să-și împărtășească gândurile și sentimentele,
            cum ar fi crearea de momente zilnice dedicate discuțiilor deschise.
          </p>
          <p>
            Tehnici de comunicare care evită conflictele – Învățarea unor fraze
            și tehnici de ascultare activă care dezamorsează tensiunile, de
            exemplu: “Vreau să înțeleg cum te simți, poți să-mi spui mai multe?”
          </p>
          <p>
            Gestionarea crizelor emoționale ale copilului – Pași pentru a ajuta
            copilul să-și exprime emoțiile fără să ajungă la izbucniri, cum ar
            fi utilizarea tehnicii “time-in” în loc de “time-out”.
          </p>
          <p>
            Cum să creezi un spațiu de dialog sigur – Ghid pentru stabilirea
            unor reguli de comunicare în familie, cum ar fi sesiuni regulate de
            discuții fără judecată în care copilul poate vorbi liber.
          </p>
          <p>
            Transformarea certurilor zilnice în colaborare – Strategii pentru a
            folosi situațiile tensionate (cum ar fi certurile legate de teme sau
            treburile casnice) ca ocazii de învățare și rezolvare comună a
            problemelor.
          </p>
          <p>
            Activități pentru a reconstrui conexiunea emoțională – Exerciții
            practice, cum ar fi petrecerea de timp unu-la-unu în activități care
            îi fac plăcere copilului, pentru a reface legătura pierdută.
          </p>
          <h3>Cum pot beneficia de program?</h3>
          <p>
            Pentru parinti sedinta de 2 ore presupune o donatie de minim 60 lei.
            Donatie se face prin transfer direct in contul asociatiei cu
            specificatia "DONATIE" Dupa efectuarea transferului se trimite pe
            e-mail-ul asociatiei dovada donatiei si datele dvs de contact. In
            urma donatiei veti fi contactati pentru program.
          </p>
        </div>
      </div>
    </section>
  );
};

export default StopParenting;
